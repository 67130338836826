<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card-code title="Liste des Informations">
          <b-button
            v-if="userData.role!=='ADMIN'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-add-information
            variant="outline-primary"
          >
            Ajouter
          </b-button>
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="informationcolumns"
            :rows="informations"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="showDetailInformation(props.row)">
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Détail</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else-if="props.column.field === 'createdAt'">
                {{ getDate(props.row.createdAt) }}
              </span>
              <span v-else-if="props.column.field === 'typeInfo'">
                <b-badge :variant="statusVariant(props.row.typeInfo)">
                  {{ props.row.typeInfo==1?'Alerte':'Information' }}
                </b-badge>
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','20','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <!--    Modal Add-->
          <b-modal
            id="modal-add-information"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            cancel-title="Close"
            :ok-disabled="desableButton"
            :cancel-disabled="desableButton"
            centered
            size="lg"
            title="Ajouter une Information"
            @ok="addInformation"
          >
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Titre"
                        rules="required"
                      >
                        <b-form-input
                          v-model="information.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Titre"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-textarea
                      id="textarea-rows"
                      v-model="information.text"
                      placeholder="Votre texte"
                      rows="8"
                    />
                  </b-col>
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-file
                      v-model="information.file"
                      placeholder="Choisir une image"
                      drop-placeholder="Glisser une image"
                    />
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
          <!--    Modal Detail-->
          <b-modal
            id="modal-detail-information"
            cancel-variant="outline-secondary"
            cancel-title="Fermer"
            centered
            size="lg"
            title="Détail sur l'information"
          >
            <b-row>
              <b-col
                md="12"
                lg="12"
              >
                <b-card-text>
                  <h5>Date : </h5>
                  {{ getDate(informationDetail.createdAt) }}
                </b-card-text>

              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                lg="12"
              >

                <b-card-text>
                  <h5>Titre : </h5>
                  {{ informationDetail.title }}
                </b-card-text>

              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                lg="12"
              >
                <b-card-text>
                  <b-card-text>
                    <h5>Text : </h5>
                    {{ informationDetail.text }}
                  </b-card-text>

                </b-card-text></b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                lg="12"
              >
                <b-card-text>
                  <b-card-text>
                    <h5>Image : </h5>

                    <b-img

                      :src="getImage(informationDetail.imageUrl)"
                      fluid
                      alt="Responsive image"
                    />
                  </b-card-text>

                </b-card-text></b-col>
            </b-row>
          </b-modal>
          <!--    Modal Edit-->
          <b-modal
            id="modal-edit-information"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            cancel-title="Close"
            centered
            size="lg"
            title="Modifier une Information"
            @ok="updateInformation"
          >
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Titre"
                        rules="required"
                      >
                        <b-form-input
                          v-model="information.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Titre"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-textarea
                      id="textarea-rows"
                      v-model="information.text"
                      placeholder="Votre texte"
                      rows="8"
                    />
                  </b-col>
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-file
                      v-model="information.file"
                      placeholder="Choisir une image"
                      drop-placeholder="Glisser une image"
                    />
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-card-code>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BForm, BBadge, BModal, BImg, BFormSelect, BRow, BCol, BPagination, BFormGroup, BFormInput, BDropdown, BDropdownItem,
  BFormTextarea, BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import { getUserData } from '@/auth/utils'
import axiosInstance from '@/libs/axios'
import informationMixin from '../@core/mixins/informationMixin'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BFormFile,
    BRow,
    BCol,
    BModal,
    BImg,
    BForm,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  mixins: [informationMixin],
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      desableButton: false,
      userData: null,
      informationcolumns: [
        {
          label: 'Date',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Date',
          },
        },
        {
          label: 'Titre',
          field: 'title',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Titre',
          },
        },
        // {
        //   label: 'Zone',
        //   field: 'createdBy.zone.name',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Recherche Par Titre',
        //   },
        // },
        {
          label: 'Type',
          field: 'typeInfo',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Type',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      informationDetail: {
        title: null,
        text: null,
        file: null,
        typeInfo: null,
        createdBy: null,
        createdAt: null,
        updatedAt: null,
      },
      information: {
        title: null,
        text: null,
        file: null,
        typeInfo: null,
        createdBy: null,
        createdAt: null,
        updatedAt: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0 : 'light-warning',
        1 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {

  },
  methods: {
    getImage(link) {
      // eslint-disable-next-line no-undef
      console.log(`${axiosInstance.defaults.baseURL}/api/v1/${link}`)
      return `${axiosInstance.defaults.baseURL}/api/v1/${link}`
    },
    showDetailInformation(info) {
      this.informationDetail = info
      this.$bvModal.show('modal-detail-information')
    },
    getDate(date) {
      console.log(date)
      // moment.locale()
      moment.locale('fr')
      return moment(date).format('LLL')
      // return moment(date).format('DD-MM-YYYY HH:mm')
    },
    addInformation(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.desableButton = true
          const date = moment().valueOf()
          const userData = getUserData()
          console.log(userData.role)
          if (userData.role === 'POINTFOCAL') {
            console.log('Point focal')
            this.information.typeInfo = 0
          } else if (userData.role === 'ANPC') {
            console.log('anpc')
            this.information.typeInfo = 1
          }
          this.information.createdBy = this.userData.userId
          this.information.createdAt = date
          this.information.updatedAt = date
          this.$store.dispatch('mainAppStore/addInformationItem', this.information).then(response => {
            console.log(response.data)
            this.desableButton = false
            this.$swal({
              title: 'Succes',
              html: 'Information ajoutée avec succes',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            })
            this.$bvModal.hide('modal-add-information')
          })
            .catch(err => {
              this.desableButton = false
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.log(err)
              console.error(err.response)
            })
        }
      })
    },
    editInformation(information) {
      this.information.id = information.id
      this.information.title = information.title
      this.information.text = information.text
      this.information.cretedBy = information.cretedBy
      this.information.createdAt = information.createdAt
      this.information.updatedAt = information.updatedAt
      this.$bvModal.show('modal-edit-information')
    },
    updateInformation(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.desableButton = true
          this.$store.dispatch('mainAppStore/updateInformationItem', this.information).then(response => {
            console.log(response.data)
            this.desableButton = false
            this.$bvModal.hide('modal-edit-information')
            this.$swal({
              title: 'Succes',
              html: `${response.data.name} est modifier avec succes`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            })
          })
            .catch(err => {
              this.desableButton = false
              console.error(err.response.headers)
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
