export default {
  computed: {

    informations() {
      return this.$store.state.mainAppStore.informations
    },

  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.$store.dispatch('mainAppStore/fetchInformationsListItems').then(response => {
      console.log(response.data)
    })
      .catch(err => {
        console.error(err.response.headers)
      })
  },
}
